import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../../styles/admins/rooms/adv_book_history.css";

const AdvanceBookingHistory = (props) => {
  const [bookings, setBookings] = useState([]);

  // Fetch booking details if room is booked in advance
  useEffect(() => {
    axios
      .get(
        `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/advance-history`
      )
      .then((response) => {
        setBookings(response.data);
      })
      .catch((error) => {
        console.error("Error fetching booking details:", error);
      });
  }, [props.id]);

  // Handle check-in creation for a specific booking
  const handleCancel = async (index) => {
    props.handleLoading();
    await axios.put(
      `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/cancel-booking/${bookings[index].advance_booking_id}`
    );
    window.location.reload();
    props.closeLoading();
  };

  const handleCheckIn = async (index) => {
    props.handleLoading();
    try {
      bookings[index].live_image = null;
      bookings[index].live_image_file = null;
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${props.id}_bookings/checkin-room`,
        bookings[index],
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      props.closeLoading();
      if (response.status === 201) {
        props.handleToast({
          toastMsg: "Check In Successfully!!!",
          toastColor: "#4CAF50",
        });
        await axios.put(
          `https://api.dectwinhotel.com/api/${props.id}_advance_bookings/success-booking/${bookings[index].advance_booking_id}`
        );
        window.location.reload();
        localStorage.removeItem("rooms");
        // navigate("/admin/rooms");
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (err) {
      props.closeLoading();
    }
  };

  return (
    <section
      style={{
        backgroundColor: "#282c34",
        padding: "4rem 1rem",
        minHeight: "90vh",
      }}
    >
      <div className="advance-bookings-section">
        <h2>Advance Booking History</h2>
        <div className="table-responsive">
          <table className="advance-bookings-table">
            <thead>
              <tr>
                <th>Booking ID</th>
                <th>Guest Name</th>
                <th>Room Number</th>
                <th>Checkin Date</th>
                <th>Checkout Date</th>
                <th>Amount Paid</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking, index) => (
                  <tr key={booking.advance_booking_id}>
                    <td>ADV{booking.advance_booking_id}</td>
                    <td>{JSON.parse(booking.guest_ids).name[0]}</td>
                    <td>{booking.room_id}</td>
                    <td>{new Date(booking.from_date).toLocaleString()}</td>
                    <td>{new Date(booking.to_date).toLocaleString()}</td>
                    <td>₹{booking.advance_amount}</td>
                    <td>{booking.booking_status.toUpperCase()}</td>
                    <td>
                      {booking.booking_status === "pending" ? (
                        <div className="d-flex justify-content-center">
                          <button
                            className="checkin-btn"
                            onClick={() => handleCheckIn(index)} // Pass index here
                          >
                            Checkin
                          </button>
                          &nbsp; &nbsp;
                          <button
                            style={{ backgroundColor: "#de1919" }}
                            className="checkin-btn"
                            onClick={() => handleCancel(index)} // Assuming this is a cancel button
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          style={{
                            backgroundColor:
                              booking.booking_status === "cancelled"
                                ? "#de1919"
                                : "#4caf50",
                          }}
                          className="checkin-btn"
                        >
                          {booking.booking_status.toUpperCase()}
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No advance bookings found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <button className="add-booking-btn">
          <Link to="/admin/rooms">Create New Advance Booking</Link>
        </button>
      </div>
    </section>
  );
};

export default AdvanceBookingHistory;
