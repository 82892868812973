import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function EditSelfDetails({
  handleToast,
  clientDetails,
  handleLoading,
  closeLoading,
}) {
  const navigate = useNavigate();
  // Initial state with business details
  const [details, setDetails] = useState(clientDetails);

  // State to control password visibility
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Function to handle save/update, e.g., sending updated details to the backend
  const handleSave = async (e) => {
    e.preventDefault();
    handleLoading();

    try {
      const response = await axios.put(
        // "http://localhost:8090/api/edit-self",
        "https://api.dectwinhotel.com/api/edit-self",
        details
      );
      closeLoading();
      localStorage.removeItem("client_is");
      localStorage.setItem("client_is", response.config.data);
      handleToast({
        toastMsg: "Details Updated Successfully!!!",
        toastColor: "#4CAF50",
      });
      navigate("/admin/dashboard");
    } catch (error) {
      closeLoading();
      handleToast({
        toastMsg: "Something went wrong! Please check data or internet.",
        toastColor: "#FF0000",
      });
    }
  };

  return (
    <section style={{ padding: "4rem 0rem" }}>
      <div className="add-room-container">
        <form className="edit-details-form" onSubmit={handleSave}>
          <h2 className="form-title">Edit Business Details</h2>

          <div className="form-group">
            <label>Hotel ID:</label>
            <input
              type="text"
              name="hotel_id"
              value={details.hotel_id}
              disabled
            />
          </div>

          <div className="form-group">
            <label>Business Name:</label>
            <input
              type="text"
              name="business_name"
              value={details.business_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Business Tagline/Sub Name:</label>
            <input
              type="text"
              name="tagline"
              value={details.tagline || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Contact:</label>
            <input
              type="text"
              name="contact"
              value={details.contact}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>E-Mail:</label>
            <input
              type="text"
              name="gmail"
              value={details.gmail}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={details.address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>GSTIN Number:</label>
            <input
              type="text"
              name="gstin"
              value={details.gstin || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>OYO Registration Number:</label>
            <input
              type="text"
              name="oyo_reg"
              value={details.oyo_reg || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>FSSAI Licence Number:</label>
            <input
              type="text"
              name="fssai"
              value={details.fssai || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Footer Contents for Invoice:</label>
            <textarea
              name="footer_contents"
              value={details.footer_contents ?? ""}
              onChange={handleChange}
              rows="4"
              placeholder="Enter footer content here to be printed on invoices..."
            />
          </div>

          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={details.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Password:</label>
            <div style={{ position: "relative" }}>
              <input
                type={showPassword ? "text" : "password"}
                name="pass"
                value={details.pass}
                onChange={handleChange}
                required
                style={{ paddingRight: "2rem" }}
              />
              <i
                className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "60%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#555",
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            className="submit-btn-room"
            style={{ width: "100%", backgroundColor: "blueviolet" }}
          >
            Save Changes
          </button>
        </form>
      </div>
    </section>
  );
}

export default EditSelfDetails;
