function convertAmountToWords(amount) {
  const ones = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const convertBelowThousand = (num) => {
    if (num === 0) return "";
    if (num < 10) return ones[num];
    if (num < 20) return teens[num - 10];
    return `${tens[Math.floor(num / 10)]} ${ones[num % 10]}`.trim();
  };

  const convertAmount = (num, unit) => {
    if (num === 0) return "";
    const result = convertBelowThousand(num);
    return result ? `${result} ${unit} ` : "";
  };

  const rupees = Math.floor(amount);
  const paise = Math.round((amount - rupees) * 100);

  if (rupees === 0 && paise === 0) {
    return "Zero Rupees Only";
  }

  let remainder = rupees;
  const crore = Math.floor(remainder / 10000000);
  remainder %= 10000000;

  const lakh = Math.floor(remainder / 100000);
  remainder %= 100000;

  const thousand = Math.floor(remainder / 1000);
  remainder %= 1000;

  const hundred = Math.floor(remainder / 100);
  remainder %= 100;

  const result = [];
  if (crore > 0) result.push(convertAmount(crore, "Crore"));
  if (lakh > 0) result.push(convertAmount(lakh, "Lakh"));
  if (thousand > 0) result.push(convertAmount(thousand, "Thousand"));
  if (hundred > 0) result.push(convertAmount(hundred, "Hundred"));
  if (remainder > 0) result.push(convertBelowThousand(remainder));

  if (paise > 0) {
    result.push(`and ${convertBelowThousand(paise)} Paise Only`);
  } else {
    result.push("Rupees Only");
  }

  return result
    .join(" ")
    .trim()
    .replace(/\s{2,}/g, " ");
}

export default convertAmountToWords;
