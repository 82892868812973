import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "../../../styles/admins/invoices/invoice.css";
import { useNavigate } from "react-router-dom";
import convertAmountToWords from "../../confs/amount_words";

const rowOne = { width: "7%", textAlign: "center" };
const rowTwo = { width: "68%" };
const rowThree = { borderRight: "none", width: "25%", textAlign: "right" };

function InvoicePage(props) {
  const contentRef = useRef();
  const navigate = useNavigate();
  const reactToPrintFn = useReactToPrint({
    contentRef,
    onAfterPrint: () => {
      setTimeout(() => {
        props.toggleHeader();
        if (props.isReprint) {
          navigate(-1);
        } else {
          localStorage.removeItem("rooms");
          localStorage.removeItem(
            `food_order_${props.invoiceDetails.booking_id}`
          );
          navigate("/admin/rooms", { replace: true });
        }
      }, 500);
    },
  });

  const clientIs = localStorage.getItem("client_is");
  const clientDetails = JSON.parse(clientIs);

  const guests = JSON.parse(localStorage.getItem("guests"));

  // Function to get guest details by guestId
  const getGuestDetails = (guestId) => {
    const guest = guests.find((g) => g.id === guestId);
    if (guest) {
      return {
        name: guest.name,
        contact: guest.contact,
        address: guest.address,
        is_com: guest.is_com,
      };
    } else {
      return {
        name: JSON.parse(props.invoiceDetails.guest_data).name[0],
        contact: "",
      };
    }
  };

  const guestData = getGuestDetails(
    JSON.parse(props.invoiceDetails.guest_data).id[0]
  );

  return (
    <section className="d-flex flex-column align-items-center">
      <div className="invoice-outer col-lg-6 col-md-10 col-12" ref={contentRef}>
        <div className="invoice-container">
          <div className="invoice-header">
            <h2>{clientDetails.business_name.toUpperCase()}</h2>
            {clientDetails.tagline && (
              <p>{clientDetails.tagline.toUpperCase()}</p>
            )}
            <p>{clientDetails.address.toUpperCase()}</p>
            <p style={{ fontSize: "15.5px" }}>
              Contacts: {clientDetails.contact}
            </p>
            {clientDetails.email && (
              <p style={{ fontSize: "14px", marginBottom: "8px" }}>
                {clientDetails.email}
              </p>
            )}
            <h2 style={{ fontSize: "18px" }}>TAX INVOICE</h2>
          </div>

          <div className="invoice-details">
            <div className="registrations">
              {clientDetails.gstin !== null && (
                <p>GSTIN: {clientDetails.gstin}</p>
              )}
              {clientDetails.fssai !== null && clientDetails.fssai && (
                <p>FSSAI No.: {clientDetails.fssai}</p>
              )}
              {clientDetails.oyo_reg !== null && clientDetails.oyo_reg && (
                <p>OYO Reg No.: {clientDetails.oyo_reg}</p>
              )}
            </div>
            <div className="client-details">
              <div className="invoice-meta text-start">
                <p>Company Name: {guestData.is_com ? guestData.name : ""}</p>
                <p>Company GST: {guestData.is_com ? guestData.contact : ""}</p>
                <p>Guest Name: {guestData.is_com ? "" : guestData.name}</p>
                <p>
                  Guest Contact: {guestData.is_com ? "" : guestData.contact}
                </p>
              </div>
              <div className="invoice-meta text-start">
                <p>Invoice No: INV{props.invoiceDetails.booking_id}</p>
                <p>Room No: {props.invoiceDetails.room_no}</p>
                <p>
                  Check In: {props.invoiceDetails.from_date.slice(8, 10)}-
                  {props.invoiceDetails.from_date.slice(5, 7)}-
                  {props.invoiceDetails.from_date.slice(0, 4)}{" "}
                  {props.invoiceDetails.from_date.slice(11, 16)}
                </p>
                <p>
                  Checkout: {props.invoiceDetails.to_date.slice(8, 10)}-
                  {props.invoiceDetails.to_date.slice(5, 7)}-
                  {props.invoiceDetails.to_date.slice(0, 4)}{" "}
                  {props.invoiceDetails.to_date.slice(11, 16)}
                </p>
              </div>
            </div>
            <div style={{ height: "48px" }}>
              <p className="address-line">Address: {guestData.address}</p>
            </div>
            <div
              style={{
                height: "400px",
                borderBottom: "1px solid #000000",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: `url(${clientDetails.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "270px",
                  opacity: 0.1, // Sets only the background opacity
                  zIndex: 0, // Ensure it's behind the content
                }}
              ></div>
              <table className="invoice-table">
                <thead>
                  <tr>
                    <th style={rowOne}>S.No.</th>
                    <th style={rowTwo}>Particulars</th>
                    <th style={rowThree}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={rowOne}>1</td>
                    <td style={rowTwo}>Room Rent</td>
                    <td style={rowThree}>
                      ₹ {props.invoiceDetails.total_amount}
                    </td>
                  </tr>
                  {props.invoiceDetails.tax_amount > 0 && (
                    <tr>
                      <td style={rowOne}>2</td>
                      <td style={rowTwo}>
                        GST @{props.invoiceDetails.tax_rate}%
                      </td>
                      <td style={rowThree}>
                        ₹ {props.invoiceDetails.tax_amount}
                      </td>
                    </tr>
                  )}
                  {props.printFood && (
                    <tr>
                      <td style={rowOne}>
                        {props.invoiceDetails.tax_amount > 0 ? "3" : "2"}
                      </td>
                      <td style={rowTwo}>Food Bill</td>
                      <td style={rowThree}>
                        ₹ {props.invoiceDetails.food_price}
                      </td>
                    </tr>
                  )}
                  {props.printExtra && (
                    <tr>
                      <td style={rowOne}>
                        {props.invoiceDetails.tax_amount > 0
                          ? props.printFood
                            ? "4"
                            : "3"
                          : props.printFood
                          ? "3"
                          : "2"}
                      </td>
                      <td style={rowTwo}>Extra Charges</td>
                      <td style={rowThree}>
                        ₹ {props.invoiceDetails.extra_charges}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="invoice-total">
              <p>
                Gross Total:&nbsp;&nbsp; ₹&nbsp;
                {(
                  props.invoiceDetails.total_amount +
                  (props.printExtra ? props.invoiceDetails.extra_charges : 0) +
                  (props.printFood ? props.invoiceDetails.food_price : 0)
                ).toFixed(2)}
              </p>
              {props.invoiceDetails.tax_amount > 0 && (
                <p style={{ fontSize: "13px", fontWeight: "500" }}>
                  SGST@{(props.invoiceDetails.tax_rate / 2).toFixed(2)}%:
                  &nbsp;&nbsp;₹&nbsp;
                  {(props.invoiceDetails.tax_amount / 2).toFixed(2)}
                </p>
              )}
              {props.invoiceDetails.tax_amount > 0 && (
                <p style={{ fontSize: "13px", fontWeight: "500" }}>
                  CGST@{(props.invoiceDetails.tax_rate / 2).toFixed(2)}%:
                  &nbsp;&nbsp;₹&nbsp;
                  {(props.invoiceDetails.tax_amount / 2).toFixed(2)}
                </p>
              )}
            </div>
            <div
              style={{ height: "25px", justifyContent: "space-between" }}
              className="invoice-total d-flex"
            >
              <p>Payment Method: {props.invoiceDetails.payment_method}</p>
              <p>
                Grand Total:&nbsp;&nbsp; ₹&nbsp;
                {(
                  props.invoiceDetails.total_amount +
                  (props.printExtra ? props.invoiceDetails.extra_charges : 0) +
                  (props.printFood ? props.invoiceDetails.food_price : 0) +
                  props.invoiceDetails.tax_amount
                ).toFixed(2)}
              </p>
            </div>
            <p
              style={{
                fontWeight: "500",
                fontSize: "14px",
                margin: "0",
                padding: "0 5px",
                borderBottom: "1px solid #000000",
              }}
            >
              Amount in words:{" "}
              {convertAmountToWords(
                props.invoiceDetails.total_amount +
                  (props.printExtra ? props.invoiceDetails.extra_charges : 0) +
                  (props.printFood ? props.invoiceDetails.food_price : 0) +
                  props.invoiceDetails.tax_amount
              )}
            </p>
            <div className="footer-data">
              <div className="left-foot" style={{ paddingBottom: "5px 0" }}>
                <div className="invoice-footer">
                  {props.printRemarks && (
                    <p
                      style={{
                        fontWeight: "400",
                        margin: "0",
                      }}
                    >
                      Remarks: {props.invoiceDetails.remarks}
                    </p>
                  )}
                </div>
                <strong style={{ textAlign: "start" }}>
                  <pre style={{ margin: "0" }}>
                    {clientDetails.footer_contents}
                  </pre>
                </strong>
              </div>
              <div className="right-foot">
                <p>For {clientDetails.business_name}</p>
                <p>Authorised Signature</p>
              </div>
            </div>
            {/* <p style={{ textAlign: "center", fontWeight: "600" }}>
              Thank you for your stay!
            </p> */}
          </div>

          {/* Print Button */}
        </div>
      </div>
      <br />
      <button className="print-btn" onClick={reactToPrintFn}>
        Print Invoice
      </button>
    </section>
  );
}

export default InvoicePage;
