import React from "react";

function MyLoader(props) {
  return (
    props.show && (
      <div
        style={{
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          zIndex: 9999,
        }}
      >
        <div style={loaderStyles}></div>
      </div>
    )
  );
}

// Loader styles
const loaderStyles = {
  border: "4px solid #f3f3f3",
  borderTop: "4px solid #3498db",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  animation: "spin 1s linear infinite",
};

// Add keyframes
const loaderKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `;

// Add the keyframes to the document
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(loaderKeyframes, styleSheet.cssRules.length);

export default MyLoader;
