import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import InvoicePage from "../invoices/invoice";
import ExtraInvoice from "../invoices/extra_invs";
import axios from "axios";

const CheckoutPage = (props) => {
  const location = useLocation();

  const guests = {
    id: JSON.parse(location.state.guest_ids).id,
    name: JSON.parse(location.state.guest_ids).name,
  };

  const [foodBill, setFoodBill] = useState(false); // To control printing food bill
  const [extraCharge, setExtraCharge] = useState(false); // To control printing extra charges
  const [addRemarks, setAddRemarks] = useState(false); // To control printing remarks
  const [showInvoice, setShowInvoice] = useState(false); // To control printing remarks

  const [foodBillE, setFoodBillE] = useState(false); // To control printing food bill
  const [extraChargeE, setExtraChargeE] = useState(false); // To control printing extra charges
  const [addRemarksE, setAddRemarksE] = useState(false); // To control printing remarks
  const [showInvoiceE, setShowInvoiceE] = useState(false); // To control printing remarks

  function invoiceToggler() {
    setShowInvoiceE(false);
    setAddRemarksE(false);
    setExtraChargeE(false);
    setFoodBillE(false);
  }

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    booking_id: location.state.booking_id,
    guest_data: location.state.guest_ids,
    room_id: location.state.room_id,
    room_no: location.state.room_no,
    from_date: location.state.from_date || "",
    to_date:
      location.state.to_date === "0000-00-00 00:00:00"
        ? getCurrentDateTime()
        : location.state.to_date,
    no_of_stayers: location.state.no_of_stayers || 1,
    total_amount: location.state.total_amount || 0,
    advance_amount: location.state.advance_amount || 0,
    food_price: location.state.food_order_total,
    remarks: location.state.remarks || "",
    extra_charges: 0,
    payment_method: "",
    rest_amount: 0,
    tax_rate: 0,
    tax_amount: 0,
  });

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: parseFloat(value) || 0,
    }));
  };

  const calculateGSTAmount = (totalAmount, gstRate) => {
    return (totalAmount * gstRate) / 100;
  };

  const handleSubmitCheckout = (e) => {
    e.preventDefault();
    props.handleLoading();
    formData.tax_amount = calculateGSTAmount(
      formData.total_amount,
      formData.tax_rate
    );
    formData.rest_amount =
      formData.total_amount -
      formData.advance_amount +
      formData.extra_charges +
      formData.tax_amount;
    formData.checkout_time = getCurrentDateTime();

    axios
      .put(
        `https://api.dectwinhotel.com/api/${props.id}_bookings/checkout/${location.state.booking_id}`,
        formData
      )
      .then((response) => {
        props.closeLoading();
        props.handleToast({
          toastMsg: "Check Out Successfully!!!",
          toastColor: "#4CAF50",
        });
        setShowInvoice(true);
        props.toggleHeader();
      })
      .catch((error) => {
        props.closeLoading();
      });
  };

  return (
    <section style={{ padding: "1rem" }}>
      {!showInvoice && !showInvoiceE && (
        <div className="book-room-form">
          <h2>Checkout Room No {location.state.room_no}</h2>
          <form onSubmit={handleSubmitCheckout}>
            {guests.id.length > 0 &&
              guests.id.map((ids, index) => (
                <div key={ids} className="guest-entry">
                  <label htmlFor="guest_name">Guest {index + 1} Name</label>
                  <input
                    type="text"
                    name="guest_name"
                    value={guests.name[index]}
                    readOnly
                    required
                  />
                </div>
              ))}

            <label htmlFor="from_date">From Date</label>
            <input
              type="datetime-local"
              name="from_date"
              value={formData.from_date}
              readOnly
              required
            />

            <label htmlFor="to_date">To Date</label>
            <input
              type="datetime-local"
              name="to_date"
              value={formData.to_date}
              readOnly
            />

            <label htmlFor="no_of_stayers">Number of Adult(s)</label>
            <select
              name="no_of_stayers"
              value={formData.no_of_stayers}
              readOnly
              disabled
              required
            >
              <option value="">{formData.no_of_stayers}</option>
            </select>

            <label htmlFor="total_amount">Total Amount</label>
            <input
              type="text"
              name="total_amount"
              value={formData.total_amount}
              readOnly
              required
            />

            <label htmlFor="advance_amount">Advance Amount</label>
            <input
              type="text"
              name="advance_amount"
              value={formData.advance_amount}
              readOnly
              required
            />

            <label htmlFor="payment_method">Payment Method</label>
            <select
              name="payment_method"
              value={formData.payment_method}
              onChange={handleChange}
              required
            >
              <option value="">Select Payment Method</option>
              <option value="Cash">Cash</option>
              <option value="Unified Payment Interface (UPI)">
                Unified Payment Interface (UPI)
              </option>
              <option value="Credit Card">Credit Card</option>
              <option value="Debit Card">Debit Card</option>
            </select>

            <label htmlFor="rest_amount">Remaining Amount to Pay</label>
            <input
              type="text"
              name="rest_amount"
              value={
                formData.total_amount -
                formData.advance_amount +
                formData.extra_charges +
                calculateGSTAmount(formData.total_amount, formData.tax_rate)
              }
              readOnly
              required
            />

            <label htmlFor="remarks">Remarks</label>
            <textarea
              name="remarks"
              placeholder="Add any remarks here"
              value={formData.remarks}
              onChange={handleChange}
            />

            <label htmlFor="food_charges">Food Order Amount</label>
            <input
              type="text"
              name="food_charges"
              value={formData.food_price}
              readOnly
              required
            />

            <label htmlFor="extra_charges">Extra Charges</label>
            <input
              type="text"
              name="extra_charges"
              value={formData.extra_charges}
              onChange={handleNumberChange}
              required
            />

            <div className="gst-row">
              <label htmlFor="tax_rate">GST Rate</label>
              <input
                type="text"
                name="tax_rate"
                value={formData.tax_rate}
                onChange={handleNumberChange}
                required
              />

              <label htmlFor="tax_amount">GST Amount</label>
              <input
                type="text"
                name="tax_amount"
                value={calculateGSTAmount(
                  formData.total_amount,
                  formData.tax_rate
                )}
                readOnly
                required
              />
            </div>

            {/* Checkboxes for extra options */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "33.33% 33.33% 33.34%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={foodBill}
                  style={{ height: "20px", width: "20px" }}
                  onChange={() => setFoodBill(!foodBill)}
                />
                <label>Print Food Bill</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={extraCharge}
                  style={{ height: "20px", width: "20px" }}
                  onChange={() => setExtraCharge(!extraCharge)}
                />
                <label>Print Extra Charges</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={addRemarks}
                  style={{ height: "20px", width: "20px" }}
                  onChange={() => setAddRemarks(!addRemarks)}
                />
                <label>Print Remarks</label>
              </div>
            </div>

            <br />
            <button type="submit">Checkout Room</button>
            <br />

            <strong>Select Services to Print in Invoice</strong>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "33.33% 33.33% 33.34%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={foodBillE}
                  style={{ height: "20px", width: "20px" }}
                  onChange={() => setFoodBillE(!foodBillE)}
                />
                <label>Print Food Bill</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={extraChargeE}
                  style={{ height: "20px", width: "20px" }}
                  onChange={() => setExtraChargeE(!extraChargeE)}
                />
                <label>Print Extra Charges</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={addRemarksE}
                  style={{ height: "20px", width: "20px" }}
                  onChange={() => setAddRemarksE(!addRemarksE)}
                />
                <label>Print Remarks</label>
              </div>
            </div>

            <br />
            <button
              onClick={() => {
                setShowInvoiceE(true);
                props.toggleHeader();
              }}
              style={{ backgroundColor: "#007bff" }}
              type="button"
            >
              Print Service Invoice
            </button>
          </form>
        </div>
      )}

      {/* InvoicePage component with conditional props */}
      {showInvoice && (
        <InvoicePage
          invoiceDetails={formData}
          printFood={foodBill}
          printExtra={extraCharge}
          printRemarks={addRemarks}
          isReprint={false}
          toggleHeader={props.toggleHeader}
        />
      )}

      {/* InvoicePage component with conditional props */}
      {showInvoiceE && (
        <ExtraInvoice
          invoiceToggler={invoiceToggler}
          invoiceDetails={formData}
          printFood={foodBillE}
          printExtra={extraChargeE}
          printRemarks={addRemarksE}
          toggleHeader={props.toggleHeader}
        />
      )}
    </section>
  );
};

export default CheckoutPage;
