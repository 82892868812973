import React, { useState } from "react";
import axios from "axios";
import "../../styles/admins/add_staff.css";

function AddStaff(props) {
  const [staffData, setStaffData] = useState({
    name: "",
    email: "",
    contact: "",
    staff_id: "",
    password: "",
    role: "",
    permissions: {
      room: false,
      report: false,
      food: false,
      edit: false,
    },
  });

  const handleChange = (e) => {
    setStaffData({
      ...staffData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;

    if (value.length <= 10 && /^[0-9]*$/.test(value)) {
      if (value.length > 0 && ["6", "7", "8", "9"].includes(value[0])) {
        setStaffData({ ...staffData, [e.target.name]: e.target.value });
      } else {
        setStaffData({ ...staffData, [e.target.name]: "" });
      }
    } else {
      props.handleToast({
        toastMsg: "Invalid Contact! Please enter a valid mobile number",
        toastColor: "#FF0000",
      });
      setStaffData({ ...staffData, [e.target.name]: "" });
    }
  };

  const handlePermissionChange = (e) => {
    setStaffData({
      ...staffData,
      permissions: {
        ...staffData.permissions,
        [e.target.name]: e.target.checked,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.handleLoading();

    try {
      const response = await axios.post(
        `https://api.dectwinhotel.com/api/${props.id}_staff/add-staff`,
        staffData
      );
      props.closeLoading();
      if (response.status === 200) {
        props.handleToast({
          toastMsg: "Staff Added Successfully!!!",
          toastColor: "#4CAF50",
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        // Reset form
        setStaffData({
          name: "",
          email: "",
          contact: "",
          staff_id: "",
          password: "",
          role: "",
          permissions: {
            room: false,
            report: false,
            food: false,
            edit: false,
          },
        });
      } else {
        props.handleToast({
          toastMsg: "Something went wrong! Please check data or internet.",
          toastColor: "#FF0000",
        });
      }
    } catch (error) {
      props.closeLoading();
      props.handleToast({
        toastMsg: "Something went wrong! Please check data or internet.",
        toastColor: "#FF0000",
      });
    }
  };

  return (
    <section style={{ padding: "4rem 1rem" }}>
      <div className="add-room-container">
        {" "}
        {/* Reusing the container class */}
        <form className="add-room-form" onSubmit={handleSubmit}>
          <h2 className="form-title">Add Staff</h2>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              minLength={1}
              value={staffData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Contact:</label>
            <input
              type="text"
              name="contact"
              maxLength={10}
              value={staffData.contact}
              onChange={handleNumberChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Staff ID:</label>
            <input
              type="text"
              name="staff_id"
              maxLength={25}
              value={staffData.staff_id.toUpperCase()}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={staffData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Designation:</label>
            <input
              type="text"
              name="role"
              maxLength={100}
              value={staffData.role}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              minLength={5}
              maxLength={25}
              value={staffData.password}
              onChange={handleChange}
              required
            />
          </div>
          <h4 className="form-subtitle">Permissions</h4>
          {/* Subheading for permissions */}
          <div className="radios">
            <label>Manage Rooms</label>
            <input
              type="checkbox"
              name="room"
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              checked={staffData.permissions.room}
              onChange={handlePermissionChange}
            />
          </div>
          <div className="radios">
            <label>View Reports</label>
            <input
              type="checkbox"
              name="report"
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              checked={staffData.permissions.report}
              onChange={handlePermissionChange}
            />
          </div>
          <div className="radios">
            <label>Manage Food</label>
            <input
              type="checkbox"
              name="food"
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              checked={staffData.permissions.food}
              onChange={handlePermissionChange}
            />
          </div>
          <div className="radios">
            <label>Edit Invoice</label>
            <input
              type="checkbox"
              name="edit"
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
              checked={staffData.permissions.edit}
              onChange={handlePermissionChange}
            />
          </div>
          <br />
          <button
            type="submit"
            className="submit-btn-room"
            style={{ backgroundColor: "#ff0066" }}
          >
            Add Staff
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddStaff;
